<template>
  <div :class="$style.root">
    <div class="mb-3">
      <VRow dense>
        <VCol cols="auto" v-for="{code, text} in picker" :key="code">
          <VCheckbox class="my-0 mr-3" v-model="picked" :label="text" :value="code" hide-details />
        </VCol>
      </VRow>
    </div>
    <VDataTable
        :headers="headers"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
          itemsPerPageText: '',
        }"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count || items.length"
        :mobile-breakpoint="0"
        :item-class="rowClassControl"
        @update:page="page => $router.push({ query: { ...$route.query, page } })"
        @update:items-per-page="size => $router.push({ query: { ...$route.query, size } })"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="{text, colspan } in sup" class="text-start" :colspan="colspan" :key="text">{{text}}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td v-for="item in headers" :key="item.value">
            <template v-if="item.filterable">
              <VSelect
                  v-if="/segment/ui.test(item.filterKey)"
                  :items="[{text: 'Да', value: 'Y'}, {text: 'Нет', value: 'N'}]"
                  dense
                  clearable
                  hide-details
                  :value="$route.query[item.filterKey]"
                  @change="value => $router.push({ query: pickBy({ ...$route.query, [item.filterKey]: value }, Boolean) })"
              />
              <VTextField
                  v-else
                  dense
                  clearable
                  hide-details
                  :value="$route.query[item.filterKey]"
                  @input="value => $router.push({ query: pickBy({ ...$route.query, [item.filterKey]: value }, Boolean) })"
              />
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:item.reviews="{ item: {general: {items: {userId}}} }">
        <VBtn depressed small color="light" @click.stop="onClickReviewsShow(userId.value)">Отзывы</VBtn>
      </template>
    </VDataTable>
    <VDialog v-model="reviewsDialogShow" max-width="900" scrollable persistent>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">Отзывы о контрагенте</VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="reviewsDialogShow = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="pt-5">
          <div v-if="!userReviews.items.length">Отзывы отсутствуют</div>
          <Comment v-for="comment in userReviews.items" :disabled="pending" v-bind="comment" :key="comment.id" />
            <CommentForm class="pt-5" :disabled="pending" @submit="onUserReviewSubmit" label="Отзыв" />
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import stub from './stub';
import { reduce, forEach, map, values, compact, join, pick, omit, pickBy } from 'lodash-es';
import {mapActions, mapGetters} from 'vuex';
import { ROLES } from '@/store/user/enums';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import Comment from '@/views/pkosmr/CounterpartyCommentsView/components/Comment';
export default {
  name: 'RegistryList',
  components: {
    CommentForm,
    Comment,
  },
  props: {
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  data: function() {
    return {
      picked: [],
      options: {
        page: this.page,
        itemsPerPage: this.size
      },
      reviewsDialogShow: false,
      reviewsDialogId: null,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      userReviews: 'pkosmr/userReviews',
      pending: 'pkosmr/pending',
    }),
    head() {
      if (this.picked.length) return pick(stub, ['general', ...this.picked]);
      else return stub;
    },
    sup() {
      return map(this.head, ({ text, items }, code) => ({
        code,
        text,
        colspan: values(items).length,
      }));
    },
    picker() {
      return map(omit(stub, 'general'), ({ text }, code) => ({
        code,
        text,
      }));
    },
    headers() {
      return reduce(this.head, (acc, cur, code) => {
        forEach(cur.items, (item, index) => {
          acc.push({
            text: item.text,
            value: join(compact([code, 'items', index, 'value']), '.'),
            sortable: false,
            filterable: true,
            filterKey: join(compact(['filter', code === 'general' ? '' : code, index]), '.'),
          })
        });
        return acc;
      }, [
        {
          text: 'Отзывы',
          value: 'reviews',
          sortable: false,
          filterable: false,
        }
      ]);
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
  },
  methods: {
    ...mapActions({
      fetchUserReviews: 'pkosmr/fetchUserReviewList',
      createUserReview: 'pkosmr/createUserReview',
    }),
    pickBy,
    rowClassControl() {
      return '';
    },
    onClickReviewsShow(userId) {
      this.reviewsDialogShow = true;
      this.reviewsDialogId = userId;
    },
    onUserReviewSubmit(message) {
      const id = this.reviewsDialogId;
      this.createUserReview({ id, message }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Отзыв успешно отправлен',
        });
        return this.fetchUserReviews({ id });
      });
    },
  },
  watch: {
    reviewsDialogId: {
      handler: function () {
        const id = this.reviewsDialogId;
        this.fetchUserReviews({id});
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  th {
    font-size: 12px !important;
    min-width: 200px !important;
  }
  td {
    font-size: 12px !important;
  }
}
</style>
