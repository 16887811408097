export default {
  "general": {
    "text": "Заказчик",
    "items": {
      "companyName": {
        "text": "Организация",
      },
      "email": {
        "text": "Электронная почта",
      },
      "phone": {
        "text": "Номер телефона",
      },
      "address": {
        "text": "Юр. адрес",
      },
      "goods": {
        "text": "Годовой оборот СМР млн.руб",
      },
      "numberOfEmployees": {
        "text": "Численность рабочего персонала",
      },
    }
  },
  "teploizolyatsiya_i_elektroobogrev": {
    "text": "Теплоизоляция и электрообогрев",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "teplogazosnabzhenie": {
    "text": "Теплогазоснабжение",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "genpodryad": {
    "text": "Генподряд",
    "items": {
      "firstSegment": {
        "text": "от 50 до 500 млн. руб.",
      },
      "secondSegment": {
        "text": "от 500 до 1000 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 1000 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "elektromontazh_energ_obekty": {
    "text": "Электромонтаж (энерг.объекты)",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "mekhanomontazh": {
    "text": "Механомонтаж",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "kip_i_asutp": {
    "text": "КИП и АСУТП",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "futerovka_i_ogneupory": {
    "text": "Футеровка и огнеупоры",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "vodosnabzhenie_i_kanalizatsiya": {
    "text": "Водоснабжение и канализация",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "slabotochnye_sistemy": {
    "text": "Слаботочные системы",
    "items": {
      "firstSegment": {
        "text": "от 0 до 50 млн. руб.",
      },
      "secondSegment": {
        "text": "от 50 до 200 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 200 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "obshchestroitelnye_raboty": {
    "text": "Общестроительные работы",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "dorozhnaya_infrastruktura": {
    "text": "Дорожная инфраструктура",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "prokhodki_i_burovzryv_rab": {
    "text": "Проходки и буровзрыв раб",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "arkhitekturno_stroit_raboty": {
    "text": "Архитектурно-строит. работы",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  },
  "otoplenie_ventilyatsiya_kondits": {
    "text": "Отопление, вентиляция, кондиц",
    "items": {
      "firstSegment": {
        "text": "от 0 до 100 млн. руб.",
      },
      "secondSegment": {
        "text": "от 100 до 500 млн. руб.",
      },
      "thirdSegment": {
        "text": "свыше 500 млн. руб.",
      },
      "total": {
        "text": "Балл (общий по анкете)",
      }
    }
  }
}
